import {round} from '@common/NumberUtils'
import _ from "lodash";

export const sumAllResponses = function (form, response){
    const result = {
        totalsByQuestionCategory: {},
    };

    for (const section of form.sections || []){
        for (const item of section.items || []){
            for (const option of item.options || []){
                result.totalsByQuestionCategory[option.id] ||= {
                    text: option.value,
                    count: 0,
                }
            }
        }
    }

    for (const indexSectionResponse in response.sections || {}){
        const sectionResponse = response.sections[indexSectionResponse]
        if (sectionResponse && sectionResponse.items)
        for (const indexItemResponse in sectionResponse.items){
            const itemResponse = sectionResponse.items[indexItemResponse];
            if (itemResponse){
                result.totalsByQuestionCategory[itemResponse.responseOption] ||= {
                    text: '',
                    count: 0,
                };
                result.totalsByQuestionCategory[itemResponse.responseOption].count++;
            }
        }
    }
    return result;
};

export const countOptionsByQuestionCategories =  function (form, response){
    const result = {
        questionCategories: form.questionCategories,
        totalsByQuestionCategory: {},
    };
    const options = {};
    const questionsToCategories = {};

    for (const section of form.sections || []){
        for (const item of section.items || []){
            const category = form.questionCategories.find(a => a.id === item.categoryId);
            if (category) {
                result.totalsByQuestionCategory[item.categoryId] ||= {
                    text: category.text,
                    value: 0,
                    qtd: 0,
                };
            }
            questionsToCategories[item.id] = item.categoryId;

            for (const option of item.options || []){
                options[option.id] ||= {
                    text: option.name || '',
                    value: Number(option.value || 0),
                }
            }
        }
    }

    for (const indexSectionResponse in response.sections || {}){
        const sectionResponse = response.sections[indexSectionResponse]
        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                const categoryId = questionsToCategories[indexItemResponse] || '';
                result.totalsByQuestionCategory[categoryId].value += options[itemResponse.responseOption].value;
                result.totalsByQuestionCategory[categoryId].qtd++;
            }
        }
    }

    return result;
}

export const triadeDoTempo = function (form, response){
    const resultAux= countOptionsByQuestionCategories(form, response);
    let total = 0;
    for (const indexItem in resultAux.totalsByQuestionCategory){
        total += resultAux.totalsByQuestionCategory[indexItem].value || 0;
    }

    for (const indexItem in resultAux.totalsByQuestionCategory){
        resultAux.totalsByQuestionCategory[indexItem].value = round((resultAux.totalsByQuestionCategory[indexItem].value / total) * 100);
    }

    return resultAux;
}

export const indicadorTipologico = function (form, response){
    const auxResult = {};
    const dePara = {};

    for (const section of form.sections || []){
        for (const item of section.items || []){
            for (const option of item.options || []){
                auxResult[option.value] ||= { count: 0, }
                dePara[`${item.id}_${option.id}`] = option.value;
            }
        }
    }
    for (const indexSectionResponse in response.sections || {}){
        const sectionResponse = response.sections[indexSectionResponse]
        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                if (itemResponse) {
                    const value = dePara[`${indexItemResponse}_${itemResponse.responseOption}`];
                    if (value) {
                        auxResult[value].count++;
                    }
                }
            }
        }
    }
    const itemsToResult = [
        {first: 'E', second: 'I',},
        {first: 'S', second: 'N',},
        {first: 'T', second: 'F',},
        {first: 'J', second: 'P',},
    ];

    const result = {responseWord: {text: '', value: ''}};
    for (const item of itemsToResult){
        result.responseWord.text += ((auxResult[item.first].count >= auxResult[item.second].count) ? item.first : item.second);
    }

    return result;
}

export const indicadorDimensoesInterpessoais = function (form, response){
    // result[id_category] = {text: '', items: [{text: '', value: '';}]}
    const result = {};
    const dePara = {};
    const deParaQuestions = {};
    const deParaQuestionCategories = {};

    for (const section of form.sections || []){
        for (const item of section.items || []){
            const category = form.questionCategories.find(a => a.id === item.categoryId);
            result[item.categoryId] ||= {
                text: category.text || '',
                items: [],
            };
            deParaQuestions[item.id] = item.value;
            deParaQuestionCategories[`${section.id}_${item.id}`] = item.categoryId;
            for (const option of item.options || []){
                dePara[`${item.id}_${option.id}`] = option.value;
            }
        }
    }

    for (const indexSectionResponse in response.sections || {}) {
        const sectionResponse = response.sections[indexSectionResponse]
        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                if (itemResponse) {
                    const value = dePara[`${indexItemResponse}_${itemResponse.responseOption}`];
                    const categoryId = deParaQuestionCategories[`${indexSectionResponse}_${indexItemResponse}`];

                    result[categoryId].items.push({
                        text: deParaQuestions[indexItemResponse],
                        value: value,
                        id: indexItemResponse
                    })
                }
            }
        }
    }

    return result;
}

export const inventarioDeComportamentoPessoasDeResultado = function (form, response){
    const fatorCorrecao = 'TRPVmNDxajLquEn3i98c';

    const result = (sumAllWithMultiplierInQuestion(form, response)).totalsByQuestionCategory || {};

    result[fatorCorrecao].value += 18;
    const valueFatorCorrecao = result[fatorCorrecao].value;
    let valueLess = 0;

    if (valueFatorCorrecao >= 24)
        valueLess = 7;
    else if (valueFatorCorrecao === 22 || valueFatorCorrecao === 23)
        valueLess = 5;
    else if (valueFatorCorrecao === 20 || valueFatorCorrecao === 21)
        valueLess = 3;

    for (const index in result ){
        if (index === fatorCorrecao){ continue; }

        result[index].value = result[index].value + 5;
        result[index].factor = valueLess;
        result[index].valueAdjusted = result[index].value - valueLess;
    }

    return result;
}

export const sumAllWithMultiplierInQuestion = function (form, response){
    const result = {
        totalsByQuestionCategory: {},
    };
    const options = {};
    const questionsToCategories = {};
    const valueQuestions = {};

    for (const section of form.sections || []){
        for (const item of section.items || []){
            const category = form.questionCategories.find(a => a.id === item.categoryId);
            if (category) {
                result.totalsByQuestionCategory[item.categoryId] ||= {
                    text: category.text,
                    value: 0,
                    valueLess: 0,
                    valuePlus: 0,
                };
            }
            questionsToCategories[item.id] = item.categoryId;
            valueQuestions[item.id] = item.value;

            for (const option of item.options || []){
                options[option.id] ||= {
                    text: option.name || '',
                    value: Number(option.value || 0),
                }
            }
        }
    }

    for (const indexSectionResponse in response.sections || {}) {
        const sectionResponse = response.sections[indexSectionResponse];
        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                const categoryId = questionsToCategories[indexItemResponse] || '';
                result.totalsByQuestionCategory[categoryId].value += (options[itemResponse.responseOption].value * valueQuestions[indexItemResponse]);

                if (valueQuestions[indexItemResponse] < 0) {
                    result.totalsByQuestionCategory[categoryId].valueLess += (options[itemResponse.responseOption].value * valueQuestions[indexItemResponse]);
                } else {
                    result.totalsByQuestionCategory[categoryId].valuePlus += (options[itemResponse.responseOption].value * valueQuestions[indexItemResponse]);
                }
            }
        }
    }

    return result;
}

export const tableWithCategoriesAndQuestions = function (form, response){
    const result = {
        questionCategories: form.questionCategories,
        sections: [],
        totalsByQuestionCategory: [],
    };

    const options = {};
    const questionsToCategories = {};
    const sectionNames = {};
    const itemNames = {};

    for (const section of form.sections || []){
        sectionNames[section.id] = section.name;
        for (const item of section.items || []){
            questionsToCategories[item.id] = item.categoryId;
            itemNames[item.id] = item.name;
            for (const option of item.options || []){
                options[option.id] ||= {
                    text: option.name || '',
                    value: Number(option.value || 0),
                }
            }
        }
    }

    const totalsByQuestionCategory = {};
    for (const indexSectionResponse in response.sections || {}){
        const sectionResponse = response.sections[indexSectionResponse];
        const sectionSave = {text: sectionNames[indexSectionResponse], items: []};

        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                sectionSave.items.push({
                    value: options[itemResponse.responseOption].value,
                    text: itemNames[indexItemResponse],
                    categoryId: questionsToCategories[indexItemResponse]
                });

                totalsByQuestionCategory[questionsToCategories[indexItemResponse]] ||= {
                    value: 0,
                };
                totalsByQuestionCategory[questionsToCategories[indexItemResponse]].value += options[itemResponse.responseOption].value;
            }
        }
        result.sections.push(sectionSave);
    }
    result.totalsByQuestionCategory = totalsByQuestionCategory;

    return result;
}

export const maxValueThree = function (form, response){
    const result = {
        questionCategories: form.questionCategories,
        sections: {},
        totalsByQuestionCategory: [],
    };
    const options = {};
    const questionsToCategories = {};
    const categories = {};

    for (const section of form.sections || []){
        for (const item of section.items || []){
            const category = form.questionCategories.find(a => a.id === item.categoryId);
            if (category) {
                categories[item.categoryId] ||= {
                    text: category.text,
                    value: 0,
                };
            }
            questionsToCategories[item.id] = item.categoryId;

            for (const option of item.options || []){
                options[option.id] ||= {
                    text: option.name || '',
                    value: Number(option.value || 0),
                }
            }
        }
    }

    let count = 0;
    const totalsByQuestionCategory = {};
    for (const indexSectionResponse in response.sections || {}){
        const sectionResponse = response.sections[indexSectionResponse]

        result.sections[indexSectionResponse] = {
            categories: {},
            text: String(count++),
        };
        for (const indexCategory in categories){
            result.sections[indexSectionResponse].categories[indexCategory] = {
                value: '',
            }
        }

        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                const categoryId = questionsToCategories[indexItemResponse] || '';

                result.sections[indexSectionResponse].categories[categoryId].value = options[itemResponse.responseOption].value;

                totalsByQuestionCategory[questionsToCategories[indexItemResponse]] ||= {
                    value: 0,
                };
                totalsByQuestionCategory[questionsToCategories[indexItemResponse]].value += options[itemResponse.responseOption].value;
            }
        }
    }
    result.totalsByQuestionCategory = totalsByQuestionCategory;

    return result;
}

// Quando for fazer a regra disso aqui tu precisas lembrar que não pode ter mais do que duas
// questões em cada seção.
export const maxValueFiveAndAnswerIsDivided = function (form, response) {
    const options = {};
    const questionsToCategories = {};
    const categories = {};

    form.questionCategories = form.questionCategories.sort((a,b) => a.order - b.order);
    let first = '';
    let second = '';

    for (const questionCategory of form.questionCategories || []) {
        if (!first){ first = questionCategory.id; }
        else if (!second) { second = questionCategory.id; }
    }

    for (const section of form.sections || []) {
        for (const item of section.items || []) {
            const category = form.questionCategories.find(a => a.id === item.categoryId);
            if (category) {
                categories[item.categoryId] ||= {
                    text: category.text,
                    value: 0,
                };
            }
            questionsToCategories[item.id] = item.categoryId;

            for (const option of item.options || []) {
                options[option.id] ||= {
                    text: option.name || '',
                    value: Number(option.value || 0),
                }
            }
        }
    }
    const auxResults = {};
    for (const indexCategory in categories) {
        const category = categories[indexCategory];
        auxResults[indexCategory] = {
            value: 0,
            text: category.text
        }
    }

    for (const indexSectionResponse in response.sections || {}) {
        const sectionResponse = response.sections[indexSectionResponse]

        if (sectionResponse && sectionResponse.items) {
            for (const indexItemResponse in sectionResponse.items) {
                const itemResponse = sectionResponse.items[indexItemResponse];
                const categoryId = questionsToCategories[indexItemResponse] || '';

                auxResults[categoryId].value += options[itemResponse.responseOption].value;
            }
        }
    }

    if (Object.keys(auxResults).length > 2) {
        throw 'Não pode ser calculado com mais de duas categorias para este tipo';
    }

    return {
        first: {
            value: round(auxResults[first].value),
            text: auxResults[first].text,
        },
        second: {
            value: round(auxResults[second].value),
            text: auxResults[second].text,
        },
        result: round(auxResults[first].value / auxResults[second].value)
    };
}






















