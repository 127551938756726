"use strict";
// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};

toExport.rules = {
  adm: [
    'adm',
    'sell',
    'analy',
    'sup',
  ],
  cli: [
  ],
  comp: [
    'adm',
    'edit',
    'deli',
    'att',
  ],
};

toExport.apiErrorCode = {
  permissionDenied: 'permissionDenied',
  statusError: 'statusError',
  inputError: 'inputError',
  unknown: 'unknown',
  exhaustedAttempts: 'exhaustedAttempts',

  paymentError: 'paymentError',
  bagError: 'bagError',
  paymentInvalidValue: 'paymentInvalidValue',
  notFound: 'notFound',
  emailNotConfig: "emailNotConfig",

  bagEmptyProduct: 'bagEmptyProduct',
  bagEmptyAddress: 'bagEmptyAddress',
  OK: 'OK',
  pending: 'pending',
  warningAnswered: 'warningAnswered',
  timeOver: 'timeOver',

};

// response example: {isOk:false, errorCode: orderErrorCode.unknown, msg:''}

toExport.VerifyStatus = {
  approved: "approved",
  pending: "pending",
  waitApproved: "waitApproved",
};

toExport.JobState = {
  NOT_STARTED: "NOT_STARTED",
  RUNNING: "RUNNING",
  FINISH: "FINISH",
};


toExport.Accommodation = {
  NURSERY: "NURSERY",
  APARTMENT: "APARTMENT",
  OUTPATIENT: "OUTPATIENT"
};

toExport.source = {
  AXREG: "AXREG",
  WEB: "WEB",
  iOS: "iOS",
  ANDROID: "ANDROID",
  SYNC: "SYNC",
  SCRYPT: "SCRYPT",
};

toExport.ProcedureType = {
  KOVERRIDE: "KOVERRIDE",
  KCUSTOM: "KCUSTOM",
  KCBHPM: "KCBHPM"
};

toExport.EventStatus = {
  AwaitingCheck: "AwaitingCheck",
  WaitSendGuia: "WaitSendGuia",
  WaitPayment: "WaitPayment",
  WaitSendRecourse: "WaitSendRecourse",
  WaitRecourse: "WaitRecourse",
  Finished: "Finished",
  Received: "Received",
  Losed: "Losed",
  Cuted: "Cuted",
  Released: "Released",
  Imported: "Imported",
};

toExport.EventEstatusProcessing = [
  toExport.EventStatus.AwaitingCheck,
  toExport.EventStatus.WaitSendGuia,
  toExport.EventStatus.WaitPayment,
  toExport.EventStatus.WaitSendRecourse,
  toExport.EventStatus.WaitRecourse,
];

toExport.EventActions = {
  edit: 1,
  check: 2,
  sendGuia: 4,
  receiveAll: 8,
  loseAll: 16,
  recourseIdentify: 32,
  recourseSend: 64,
  finalize: 128,
  cancel: 256,
  glosaAll: 512,
  editProcedure: 1024,
  recourseReceived: 2048,
  showHistory: 4096,
  showInvoice: 8192,
  showAdditionalData: 16384,
  revertToAwaitCheck: 32768,
  remove: 65536,
};

toExport.states = [
  {id:28, code:'--'},
  {id:1, code:'AC'},
  {id:2, code:'AL'},
  {id:3, code:'AP'},
  {id:4, code:'AM'},
  {id:5, code:'BA'},
  {id:6, code:'CE'},
  {id:7, code:'DF'},
  {id:8, code:'ES'},
  {id:9, code:'GO'},
  {id:10, code:'MA'},
  {id:11, code:'MT'},
  {id:12, code:'MS'},
  {id:13, code:'MG'},
  {id:14, code:'PA'},
  {id:15, code:'PB'},
  {id:16, code:'PR'},
  {id:17, code:'PE'},
  {id:18, code:'PI'},
  {id:19, code:'RJ'},
  {id:20, code:'RN'},
  {id:21, code:'RS'},
  {id:22, code:'RO'},
  {id:23, code:'RR'},
  {id:24, code:'SC'},
  {id:25, code:'SE'},
  {id:26, code:'SP'},
  {id:27, code:'TO'},
];


toExport.access = {
  user: 'user',
  admin: 'admin',
};

toExport.accessList = ['user', 'admin'];

toExport.porte2PorteAnestesico = {
  '3A': '1',
  '3C': '2',
  '4C': '3',
  '6B': '4',
  '7C': '5',
  '9B': '6',
  '10C': '7',
  '12A': '8',
};


toExport.OcrCompType = {
  text: 'text',
  date: 'date',
  number: 'number',
  label: 'label'
};

toExport.OcrStatus = {
  KWaitingAnalyze: 'KWaitingAnalyze',
  KDone: 'KDone',
  KError: 'KError',
  KErrorThreshold: 'KErrorThreshold',
  KErrorNotFoundMatriz: 'KErrorNotFoundMatriz',
};



toExport.FormInputType = {
  text: 'text',
  paragraph: 'paragraph',
  number: 'number',
  radio: 'radio',
  checkbox: 'checkbox',
  select: 'select',
  date: 'date',
  time: 'time',
  dateTime: 'dateTime',
};

toExport.FormLinkType = {
  none: 'none',
  eventId: 'eventId',
  eventAnesthetistName: 'eventAnesthetistName',
  eventPatientName: 'eventPatientName',
  eventDate: 'eventDate',
  userId: 'userId',
  userName: 'userName',
  userEmail: 'userEmail',
  companyId: 'companyId',
  companyName: 'companyName',
};


module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }
