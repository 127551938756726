<script>
/* eslint-disable vue/no-v-html */
import FcmDate from "@components/diversos/FcmDate";
import * as FcmOnCall from "@src/services/FcmOnCall";
import {appConfig} from '@src/config';
const Emitter = require('tiny-emitter');
const emitter = new Emitter();
import status from "@common/status";
import FcmTimer from "@components/diversos/FcmTime";
import * as Calculate from "@src/calculate";
import FcmDialog from '@components/diversos/FcmDialog';
import {Chart, registerables} from 'chart.js';
Chart.register(...registerables);
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
import {personalities, controlLocus} from '@src/data/infoResults';

import cloneDeep from "lodash/cloneDeep";
import {
  countOptionsByQuestionCategories,
  indicadorDeResiliencia,
  indicadorDimensoesInterpessoais,
  indicadorTipologico,
  inventarioDeComportamentoPessoasDeResultado,
  maxValueFiveAndAnswerIsDivided,
  maxValueThree,
  sumAllWithMultiplierInQuestion,
  triadeDoTempo
} from "@src/calculate";
import {round} from "@common/NumberUtils";

FcmOnCall.setConfig(appConfig);

// https://sistema.orazelo.app.br/response/orazelo/qaAnestesia?isDark=1&responseId=2FYtz6KOx59Q57ooxKBLNo
// https://sistema.orazelo.app.br/response/[companyId]/[slug]?isDark=1&responseId=[responseId]


if (window?.webkit?.messageHandlers?.formActionsHandler) {
  window.webkit.messageHandlers.formActionsHandler.postMessage({
    action: 'debug',
    data: 'jsLoading',
  });
}

function parseQuery(queryString) {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

window.jwFormDataLoaded = (args) => {
  emitter.emit('sendDataToJs', args);
};

export default {
  components: {FcmTimer, FcmDate, FcmDialog},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      default: ()=>null,
    },
  },
  data(vm) {
    return {
      form: {},
      valueId: null,
      tryingToUpdate: false,
      connectionError: false,
      FormInputType: status.FormInputType,
      FormLinkType: status.FormLinkType,
      response: {},
      rules: {
        ...vm.$validateRules(),
      },
      responseSend: false,
      isLoading: true,
      extraData: {},
      links: null,
      isNew: false,
      userAuth: {},
      hasDataLoad: false,
      promiseDataLoad: null,
      promiseDataLoadResolve: null,
      isDebug: false,
      formCompanyId: '',
      slug: '',
      versionNumber: process.env.VUE_APP_VERSION_NUMBER,
      version: process.env.VUE_APP_VERSION,
      query: {},
      info: {},
      logoUrl: null,
      extraDataMobile: {},
      errorMessage: '',
      timeOver: false,
      auxResponse: {},
      enablePercent: {
        triadeDoTempo: true,
        triadeDoTempoWithSingleOptionInSection: true,
        maxValueThreeAndResultInPercentCategory: true,
      },
      defaultString: {
        indicadorTipologico: true,
      },
      tip: '',
    }
  },
  computed: {
    canEdit() {
      return this.isNew || this.form?.canEditResponse;
    },
  },
  watch: {
    info(value, oldValue) {
      if(value!==oldValue) {

        this.$emit('changeCompanyInfo', {
          images: value?.data?.info?.companyImages || null,
          name: value?.data?.extraData?.companyName || null,
          id: value?.data?.extraData?.formCompanyId || null,
        });
      }
    },
    isLoading(value, oldValue) {
      if(value!==oldValue && !value) {
        this.$emit('finishLoading');
      }
    }
  },
  async mounted() {
    let params = window.location.pathname.split('/').filter((a)=>a);
    this.formCompanyId = params?.[1] || '';
    this.slug = params?.[2] || '';

    this.query = parseQuery(window.location.search);
    if(this.query.hasDataLoad) {
      this.hasDataLoad = JSON.parse(this.query.hasDataLoad) || false;
    }
    this.valueId = this.query?.responseId || null;
    this.promiseDataLoad = new Promise((resolve)=>{
      this.promiseDataLoadResolve = resolve;
    });
    this.emitToMobile('debug', 'mounted');
    // if(this.$route.query.responseId) {
    //   this.valueId = this.$route.query?.responseId || null;
    // }
    // if(this.$route.query.hasDataLoad) {
    //   this.hasDataLoad = JSON.parse(this.$route.query?.hasDataLoad) || false;
    // }
    // if(this.$route.query.isDebug) {
    //   this.isDebug = JSON.parse(this.$route.query?.isDebug) || false;
    // }
    emitter.on('sendDataToJs', (args) => {
      this.emitToMobile('debug', 'receivedData: '+this.isDebug?JSON.stringify(args):'No debug');
      this.processData(args);
      this.promiseDataLoadResolve?.();
      this.promiseDataLoadResolve = null;
    });
    if(window.jwFormData) {
      this.processData(window.jwFormData);
      this.promiseDataLoadResolve?.();
      this.promiseDataLoadResolve = null;
    }

    await this.loadForm();
    try {
      this.loadHorizontalChart();
      this.loadVerticalChart();
    } catch (error) {
      console.error('Error on load chart', error);
    }
  },
  destroyed() {
    emitter.off('sendDataToJs');
  },
  methods: {
    round,
    tryApplyExtraDataItem(response, data) {
      if(!data) {
        return;
      }
      if((response.type===this.FormInputType.text || response.type===this.FormInputType.paragraph) && !response.responseText) {
        response.responseText = data;
      }
      else if(response.type===this.FormInputType.date && !response.responseDate) {
        response.responseDate = data;
      }
      else if(response.type===this.FormInputType.dateTime && !response.responseDateTime) {
        response.responseDateTime = data;
      }
      else if(response.type===this.FormInputType.time && !response.responseTime) {
        let testDate = moment(data);
        if(testDate.isValid()) {
          response.responseTime = testDate.format('HH:mm')
        }
        else {
          response.responseTime = data;
        }
      }
    },
    processData(args) {
      this.userAuth = args.userAuth || {};
      this.extraDataMobile = args.info || {};
      // this.extraData = args.info || {};
      // if(this.links) {
      //   for(const index in this.extraData) {
      //     for(const local of this.links[index] || []) {
      //       if(this.response?.sections?.[local.sectionId]?.items?.[local.itemId]) {
      //         this.tryApplyExtraDataItem(this.response.sections[local.sectionId].items[local.itemId],
      //             this.extraData[index]);
      //         // if(!this.response.sections[local.sectionId].items[local.itemId].responseText) {
      //         //   this.response.sections[local.sectionId].items[local.itemId].responseText = this.extraData[index];
      //         // }
      //       }
      //     }
      //   }
      // }
      // this.response.extraData = this.response.extraData || {};
      // for(const i in this.extraData) {
      //   if(typeof this.extraData[i]==='string' && !this.response.extraData[i]) {
      //     this.response.extraData[i] = this.extraData[i];
      //   }
      // }

      this.response.userLoggedId = args.userAuth.uid /*|| this.extraDataMobile['userId'] || this.response.userLoggedId || this.extraData['userId'] */|| '';
      this.response.userLoggedName = this.extraDataMobile['userName'] || this.response.userLoggedName || this.extraData['userName'] || '';
      this.response.userLoggedCompanyId = /*this.extraDataMobile['companyId'] || this.response.userLoggedCompanyId || this.extraData['companyId'] || */'';
      this.response.companyId = /*this.response.userLoggedCompanyId*/ '';

      FcmOnCall.setAuth(args.userAuth);
    },
    async loadForm() {
      this.isLoading = true;
      this.connectionError = false;
      this.errorMessage = '';
      this.timeOver = false;
      let form = {};
      let response = {
        active: true,
        sections: {},
        formId: '',
        companyId: '',
        formCompanyId: '',
        slug: '',
      };

      if(this.hasDataLoad) {
        console.log('Esperando jwFormDataLoaded');
        await this.promiseDataLoad;
      }

      this.info = await FcmOnCall.callOnCall('forms-getInfo', {
        ...this.extraDataMobile,
        ...this.query,
        slug: this.slug,
        formCompanyId: this.formCompanyId
      });


      this.valueId = this.info?.data?.responseId || this.valueId || null;
      this.logoUrl = this.info?.data?.info?.companyImagesLogo?.url || null;

      let responseData = this.info?.data?.response || null;
      this.auxResponse = responseData;
      this.extraData = {...this.extraDataMobile, ...this.info?.data?.extraData};
      this.isNew = !responseData;
      if(this.info?.status===490) {
        this.errorMessage = this.$t('formInput.formNotFound');
        this.connectionError = true;
        this.isLoading = false;
        this.$showError({title: this.$t('formInput.errorConnection')});
        return;
      }
      if(this.info?.data?.errorCode===status.apiErrorCode.warningAnswered) {
        this.errorMessage = this.$t('formInput.responseSendPrevious');
      }
      else if(this.info?.data?.errorCode===status.apiErrorCode.OK) {
        if(this.info?.data?.info?.timeOver) {
          this.timeOver = true;
          this.errorMessage = this.$t('formInput.errorTimeOver');
        }
        form = this.info?.data?.form || null;
        this.links = {};

        for(const section of form?.sections || {}) {
          response.sections[section.id] = {
            items: {},
           // name: section.name,
          };
          for(const item of section.items) {
            response.sections[section.id].items[item.id] = {
              type: item.type,
              responseText: '',
              responseOption: null,
              responseOptions: {},
              ...responseData?.sections?.[section.id]?.items?.[item.id],
              // question: item,
            };

            if(item.link && item.link!==this.FormLinkType.none) {
              this.links[item.link] = this.links[item.link] || [];
              this.links[item.link].push({sectionId: section.id, itemId: item.id});
              this.tryApplyExtraDataItem(response.sections[section.id].items[item.id], this.extraData[item.link]);
              // if(this.extraData[item.link] && !response.sections[section.id].items[item.id].responseText) {
              //   response.sections[section.id].items[item.id].responseText = this.extraData[item.link];
              // }
            }
          }
        }
      }
      else {
        console.log('formObjs', this.info);
        this.errorMessage = this.$t('formInput.formNotFound');
        form = {};
      }
      response.formId = form.id || '';
      response.formCompanyId = form.companyId || '';
      response.slug = form.slug || '';
      response.categorySlug = form.categorySlug || '';
      response.version = form.version || 0;
      response.isSuperAdmin = form.isSuperAdmin || false;
      response.extraData = response.extraData || {};
      for(const i in this.extraData) {
        if(typeof this.extraData[i]==='string' && !response.extraData[i]) {
          response.extraData[i] = this.extraData[i];
        }
      }
      response.userLoggedId = this.userAuth.uid || this.extraDataMobile['userId'] || response.userLoggedId || this.extraData['userId'] || '';
      response.userLoggedName = this.extraDataMobile['userName'] || response.userLoggedName || this.extraData['userName'] || '';
      response.userLoggedCompanyId = this.extraDataMobile['companyId'] || response.userLoggedCompanyId ||
          this.extraData['companyId'] || response.formCompanyId || '';
      response.companyId = response.userLoggedCompanyId;

      this.response = response;
      this.form = form;
      this.isLoading = false;
      this.emitToMobile('debug', 'formLoaded');
    },
    goBack() {
     // this.$emit('input', null);
    },
    async submitResponse() {
      if (this.$refs.form && !this.$refs.form.validate()) {
        this.$showError({title: this.$t('formInput.errorValidate')});
        return;
      }
      let response = cloneDeep(this.response);
      response.id = this.valueId || null;
      this.tryingToUpdate = true;

      for(const section of this.form.sections) {
        for (const item of section.items) {
          if(response.sections[section.id].items[item.id].responseNumber) {
            response.sections[section.id].items[item.id].responseNumber = parseFloat(response.sections[section.id].items[item.id].responseNumber);
          }
          else if(response.sections[section.id].items[item.id].responseNumber===0) {}
          else {
            response.sections[section.id].items[item.id].responseNumber = null;
          }
        }
      }

      let result = null;
      switch (this.form.type) {
        case 'sumAllResponses':
          result = Calculate.sumAllResponses(this.form, response);
              break;
        case 'countOptionsByQuestionCategories':
        case 'countOptionsByQuestionCategoriesWithSingleAnswerInSection':
          result = Calculate.countOptionsByQuestionCategories(this.form, response);
              break;
        case 'triadeDoTempo':
        case 'triadeDoTempoWithSingleOptionInSection':
        case 'maxValueThreeAndResultInPercentCategory':
          result = Calculate.triadeDoTempo(this.form, response);
              break;
        case 'indicadorTipologico':
          result = Calculate.indicadorTipologico(this.form, response);
              break;
        case 'indicadorDimensoesInterpessoais':
          result = Calculate.indicadorDimensoesInterpessoais(this.form, response);
              break;
        case 'inventarioDeComportamentoPessoasDeResultado':
          result = Calculate.inventarioDeComportamentoPessoasDeResultado(this.form, response);
              break;
        case 'sumAllWithMultiplierInQuestion':
          result = Calculate.sumAllWithMultiplierInQuestion(this.form, response);
              break;
        case 'tableWithCategoriesAndQuestions':
          result = Calculate.tableWithCategoriesAndQuestions(this.form, response);
              break;
        case 'maxValueThree':
          result = Calculate.maxValueThree(this.form, response);
              break;
        case 'maxValueFiveAndAnswerIsDivided':
          result = Calculate.maxValueFiveAndAnswerIsDivided(this.form, response);
              break;
        default:
              break;
      }

      // if (true){ return; }

      response.result = result;
      this.auxResponse = {results: [{result: result}]};

      if(this.hasDataLoad) {
        console.log('Esperando jwFormDataLoaded');
        await this.promiseDataLoad;
      }
      try {
        let data = {response};
        if(this.query.fcmDebug) {
          data.fcmDebug = true;
        }

        data.response.isSelf = Boolean(window.jwFormData?.isSelf);
        data.response.answeredOnBehalfOf = String(window.jwFormData?.answeredOnBehalfOf || '');
        let ret = await FcmOnCall.callOnCall('forms-setResponse', data);
        if(ret.status===200 && ret.data?.errorCode===status.apiErrorCode.OK) {
          // this.$showSuccess();
          this.responseSend = true;
          this.valueId = ret.data?.data?.id;
          // this.emitToMobile('success', this.valueId);
          console.log('sucesso', this.valueId);
        }
        else if(ret.status===200 && ret.data?.errorCode===status.apiErrorCode.timeOver) {
          console.error('Erro Tempo permitido para resposta excedido');
          this.$showError({title: this.$t('formInput.errorTimeOver')});
          this.emitToMobile('error', 'Erro Tempo permitido para resposta excedido');
        }
        else if(ret.status===200 && ret.data?.errorCode===status.apiErrorCode.permissionDenied) {
          console.error('errorPermissionDenied');
          this.$showError({title: this.$t('formInput.errorPermissionDenied')});
          this.emitToMobile('error', 'errorPermissionDenied');
        }
        else {
          if(ret.status===490) {
            this.$showError({title: this.$t('formInput.errorConnection')});
            return;
          }
          else {
            console.error('Erro ao gravar resposta com onCall', ret);
            this.$showError({title: this.$t('formInput.errorOnWriteCall')});
            this.emitToMobile('error', 'Erro ao gravar com onCall: '+JSON.stringify(ret));
          }
        }
      } catch(err) {
        console.error('Erro ao gravar resposta', err);
        this.$showError({title: this.$t('formInput.errorOnSave')});
        this.emitToMobile('error', 'Erro ao salvar dados: '+(err?.message || ''));
      }

      function loadCharts(that) {
        try {
          that.loadHorizontalChart();
          that.loadVerticalChart();
        } catch (error) {
          console.error('Error on load chart', error);
        }
      }
      setTimeout(() => loadCharts(this), 500);


      this.tryingToUpdate = false;
    },
    async cancel() {
      this.emitToMobile('cancel');
    },
    async testando() {
      this.isLoading = true;
      const formObjs = await FcmOnCall.callOnCall('forms-getForm', {slug: this.slug, companyId: this.companyId});
      // console.log('Response', formObjs);
      this.isLoading = false;
    },
    emitToMobile(action, data) {
      if (window?.webkit?.messageHandlers?.formActionsHandler) {
        window.webkit.messageHandlers.formActionsHandler.postMessage({
          action,
          data: data || '',
        });
      }
      if(typeof androidInterface==='object' && typeof androidInterface?.onStatusChanged==='function') {
        androidInterface.onStatusChanged(action, data||'');
      }
    },
    changeOptions(sectionId, itemId) {
      let item = this.response.sections[sectionId].items[itemId];
      if(item.responseOption!=='other' && item.responseText) {
        this.$set(this.response.sections[sectionId].items[itemId], 'responseText', '');
      }
    },
    changeOptionsCheckbox(sectionId, itemId) {
      let item = this.response.sections[sectionId].items[itemId];
      if(!item.responseOption?.other && item.responseText) {
        this.$set(this.response.sections[sectionId].items[itemId], 'responseText', '');
      }
    },
    checkboxRequired(options) {
      for(const i in options) {
        if(options[i]) {
          return true;
        }
      }
      return false;
    },
    checkSum(currentItem, section, sectionResponse){
      switch (this.form.type) {
        case 'maxValueThree':
        case 'maxValueThreeAndResultInPercentCategory':
          return this.checkMaxValue(currentItem, section, sectionResponse, 3);
        case 'maxValueFiveAndAnswerIsDivided':
          return this.checkMaxValue(currentItem, section, sectionResponse, 5);
        case 'tableWithCategoriesAndQuestions':
        case 'triadeDoTempoWithSingleOptionInSection':
        case 'countOptionsByQuestionCategoriesWithSingleAnswerInSection':
          return this.checkSingleResponseInSection(currentItem, section, sectionResponse, 5);
        default:
          return true;
      }
    },
    checkMaxValue(currentItem, section, sectionResponse, maxValue = 0){
      let sumTotal = 0;
      let countResponses = 0;
      for (const responseIndex in sectionResponse.items) {
        const response = sectionResponse.items[responseIndex] || null;
        const item = section.items?.find(a => a.id === responseIndex);
        if (response && item && item.options){
          const option = item.options.find(a => a.id === response.responseOption);
          if (option){
            countResponses++;
            sumTotal += Number(option.value);
          }
        }
      }

      return countResponses === 2 && sumTotal !== maxValue ?
          'A soma das respostas está incorreta, o resultado final da seção deve ser ' + maxValue :
          true;
    },
    checkSingleResponseInSection(currentItem, section, sectionResponse){
      const countRepeat = {};
      let optionsAvailable = [];
      let hasRepeated = false;

      for (const item of section.items){
        for (const option of item.options){
          if (!optionsAvailable.find(a => a.id === option.id)){
            optionsAvailable.push(option);
          }
        }
      }

      for (const responseIndex in sectionResponse.items) {
        const response = sectionResponse.items[responseIndex] || null;
        if (response && response.responseOption){
          countRepeat[response.responseOption] ||= 0;
          countRepeat[response.responseOption]++;
          optionsAvailable = this.$reject(optionsAvailable, a => a.id === response.responseOption);
          if (countRepeat[response.responseOption] > 1){
            hasRepeated = true;
          }
        }
      }
      return hasRepeated ?
          'A mesma opção não pode ser selecionada duas vezes. Opções disponíveis: ' + optionsAvailable.map(a => a.name):
          true;
    },
    openTip(idItem, type) {
      this.tip = '';
      switch (type){
        case 'section':
          for (const section of this.form.sections || []){
            for (const item of section.items || []){
              if (item.id === idItem){
                this.tip = item.tip;
              }
            }
          }
          break;
        case 'category':
          for (const category of this.form.questionCategories || []){
            if (category.id === idItem){
              this.tip = category.tip;
            }
          }
          break;
        case 'personality':
          this.tip = personalities[idItem];
          break;
        case 'controlLocus':
          this.tip = controlLocus;
          break;
        default:
          break;
      }

      this.$refs.FcmDialog.openModal();
    },
    loadHorizontalChart() {
      let data = [];
      const labels = [];
      let min = 0;
      let max = 5;
      let stepSize = 1;

      const deParaTypes = {
        maxValueThree : 'chartWithMaxValueThree',
        tableWithCategoriesAndQuestions : 'chartWithCategoriesAndQuestions',
        countOptionsByQuestionCategories : 'chartCountOptionsByQuestionCategories',
        countOptionsByQuestionCategoriesWithSingleAnswerInSection : 'chartCountOptionsByQuestionCategories',
        default : 'default',
      }

      const chart = deParaTypes[this.form.type];

      const ctx = document.getElementById(chart);
      if (!ctx) { return; }

      const resultAux = this.auxResponse?.results?.[0] || null;
      const result = resultAux.result || null;

      if (!result) { return; }

      switch (this.form.type) {
        case 'maxValueThree':
        case 'tableWithCategoriesAndQuestions':
          ctx.style.width = '100% !important';
          ctx.style['min-height'] = '400px';

          let auxTotals = [];
          const totalsOrdered = {};

          const totalsByQuestionCategory = result.totalsByQuestionCategory || {};
          if (result){
            for (const category of result.questionCategories || []) {
              labels.push(category.text);

              auxTotals.push({id: category.id, total: totalsByQuestionCategory[category.id].value || 0});
            }
            auxTotals.sort((a, b) => a.total - b.total);

            let count = 0.8;
            for (const item of auxTotals) {
              totalsOrdered[item.id] = count;
              count += 1;
            }
          }

          data = result?.questionCategories?.map(a => { return {y: totalsOrdered[a.id], label: totalsByQuestionCategory[a.id].value || 0}});
          break;
        case 'countOptionsByQuestionCategories':
        case 'countOptionsByQuestionCategoriesWithSingleAnswerInSection':
          ctx.style.width = '100% !important';
          ctx.style['min-height'] = '400px';
          for (const index in result.totalsByQuestionCategory || {}) {
            const category = result.totalsByQuestionCategory[index];
            labels.push(category.text)
            data.push({y: category.value || 0, label: category.value || ''});
          }
          max = 30;
          stepSize = 5;
          break;
        default:


          break;
      }

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            // label: 'Gráfico Estilos de Negociação',
            label: '',
            data: data,
            parsing: {
              // yAxisKey: 'y',
              xAxisKey: 'y',
            },
            borderWidth: 1,
            borderColor: '#3acdc8',
            backgroundColor: '#1e1e1e',
            borderRadius: 4,
            tension: 0.1,

            fill: false,
            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false, // Important to allow the canvas to resize
          scales: {
            y: {
              beginAtZero: true,
              min: min,
              max: max,
              ticks: {
                stepSize: stepSize,
                color: "white",
              },
              pointLabel: true
            },
            x: {
              ticks: {
                color: "white",
                // font: {
                //   size: 8
                // },
              },
            }
          },
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: function(context) {
                  return context.raw;
                }
              }
            },
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              color: 'pink',
              // anchor: 'end',
              align: 'top',
              // offset: 9,

              labels: {
                offset: 60,
                value: {},
                title: {
                  color: '#3acdc8'
                }
              }
            }
          },

        },
      });
    },
    loadVerticalChart() {
      let data = [];
      const labels = [];
      let min = 0;
      let max = 25;
      let stepSize = 10;

      const deParaTypes = {
        inventarioDeComportamentoPessoasDeResultado : 'chartInventarioDeComportamentoPessoasDeResultado',
        sumAllWithMultiplierInQuestion : 'chartSumAllWithMultiplierInQuestion',
        default : 'default',
      }

      const chart = deParaTypes[this.form.type];

      const ctx = document.getElementById(chart);
      if (!ctx) { return; }

      const resultAux = this.auxResponse?.results?.[0] || null;
      const result = resultAux.result || null;
      if (!result) { return; }

      ctx.style.width = '100% !important';
      ctx.style['min-height'] = '400px';
      ctx.style['min-width'] = '300px';

      switch (this.form.type) {
        case 'inventarioDeComportamentoPessoasDeResultado':
          for (const index in result) {
            const response = result[index];
            const addData = {y: response.valueAdjusted, label: response.valueAdjusted};
            labels.push(response.text || '');
            data.push(addData);
          }
          break;
        case 'sumAllWithMultiplierInQuestion':
          for (const index in result.totalsByQuestionCategory) {
            const response = result.totalsByQuestionCategory[index];
            const addData = {y: response.value, label: response.value};
            labels.push(response.text || '');
            data.push(addData);
          }
          max = 15;
          stepSize = 5;
          break;
        default:

          break;
      }

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            // label: 'Gráfico de Inventário de Comportamentos de Pessoas de Resultado',
            label: '',
            data: data,
            parsing: {
              // yAxisKey: 'y',
              xAxisKey: 'y',
            },
            borderWidth: 1,
            borderColor: '#3acdc8',
            backgroundColor: '#1e1e1e',
            borderRadius: 4,
            tension: 0.1,

            fill: false,
            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false, // Important to allow the canvas to resize
          indexAxis: 'y',
          scales: {
            x: {
              beginAtZero: true,
              min: min,
              max: max,
              ticks: {
                stepSize: stepSize,
                color: "white",
              },
              pointLabel: true
            },
            y: {
              ticks: {
                color: "white",
                font: {
                  size: 8
                },
              },
            }
          },
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: function(context) {
                  return context.raw;
                }
              }
            },
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              color: 'pink',
              // anchor: 'end',
              align: 'left',
              // offset: 9,

              labels: {
                offset: 60,
                value: {},
                title: {
                  color: '#3acdc8'
                }
              }
            }
          },

        },
      });
    }
  }
}
</script>

<template>
  <div class="box mb-10 formResponse">
    <div v-if="isLoading || tryingToUpdate" class="content loading">
      <VProgressCircular indeterminate color="primary" :size="70" :width="7"></VProgressCircular>
    </div>
    <div v-else-if="connectionError" class="content mainMessage">
      {{$t('formInput.errorConnection')}} <br>
      <VBtn class="mr-2 mt-2" @click="loadForm"><VIcon class="mr-2">mdi-refresh</VIcon>{{$t('formInput.tryLoadFormAgain')}}</VBtn>
    </div>
    <div v-else-if="errorMessage && (!timeOver && isNew)" class="content mainMessage">
      {{errorMessage}}
    </div>
<!--    <div v-else-if="responseSend" class="content mainMessage">-->
<!--      {{$t('formInput.responseSend')}}-->
<!--    </div>-->
    <div v-else class="content">
      <div v-if="canEdit && timeOver" class="content mainMessage">
        {{errorMessage}}
      </div>
      <div v-else-if="responseSend" class="content mainMessage">
        {{$t('formInput.responseSend')}}
      </div>
<!--      <VBtn text class="pl-1" @click.stop="cancel" >-->
<!--        <VIcon x-large left>mdi-chevron-left</VIcon>-->
<!--        {{$t('all.goBack') }}-->
<!--      </VBtn>-->
      <h3 class="header">{{$trdb(form.name)}}</h3>
      <div class="body ck-content" v-html="$trdb(form.body)"></div>
      <VForm ref="form" class="sections" :disabled="!canEdit || timeOver || responseSend">
        <br/>
        <div v-if="auxResponse?.results?.length">
          <h2>Resultado</h2>
          <VCard
              v-for="(item, index) in (auxResponse.results || [])"
              :key="index"
              elevation="2"
              outlined
              shaped
              class="my-2 mx-2"
          >
            <VCardTitle> {{ item.meta && item.meta.updatedAt ? moment((item.meta.updatedAt._seconds*1000)+(item.meta.updatedAt._nanoseconds/10e6)).format('DD/MM/YYYY HH:mm') : ''}} </VCardTitle>
            <VDivider></VDivider>

            <div v-if="form.type === 'indicadorDimensoesInterpessoais'">
              <div v-for="(itemResult, indexResult) in item.result" :key="indexResult" class="mx-2 my-1">
                <h3> {{ itemResult.text }}</h3>
                <div v-for="(itemQuestion, indexQuestion) in itemResult.items" :key="indexQuestion">
                  <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 4px 4px;">
                    <div> {{ itemQuestion.text}}</div>
                    <div style="display: flex; flex-direction: row;">
                      <div > {{ itemQuestion.value }} </div>
                      <div class="mx-2">
                        <VIcon color="primary"  @click="openTip(itemQuestion.id, 'section')">mdi-lightbulb-on-outline</VIcon>
                      </div>
                    </div>
                  </div>
                  <VDivider></VDivider>
                </div>
                <br/>
              </div>
            </div>
            <div v-else-if="form.type === 'tableWithCategoriesAndQuestions'" class="divContainer" style="width: 100%; padding: 10px">
              <div style="overflow: scroll">
                <table style="width: 100%">
                  <tr>
                    <th></th>
                    <th v-for="(category, indexCategory) in item.result.questionCategories" :key="indexCategory">
                      {{category.text}}
                    </th>
                  </tr>
                  <tbody>
                    <tr v-for="(section, sectionIndex) in item.result.sections"
                        :key="sectionIndex"
                    >
                      <td>
                        {{section.text}}
                      </td>
                      <td v-for="(itemOption, indexOption) in section.items"
                          style="text-align: center"
                          :key="indexOption"
                      >
                        {{ itemOption.value}}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> Total </th>
                      <td v-for="(itemOption, indexOption) in item.result.totalsByQuestionCategory"
                          style="text-align: center"
                          :key="indexOption"
                      >
                        {{ itemOption.value }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <br/>
              <VDivider></VDivider>
              <br/>
              <span style="font-size: 16px;">
                Os pontos foram registrados em forma de gráfico, sendo a pontuação maior correspondente a 5,
                depois 4, 3, 2 e assim até a menor pontuação ser 1.
              </span>
              <br/>
              <br/>
              <div class="chart">
                <canvas id="chartWithCategoriesAndQuestions" />
              </div>
            </div>
            <div v-else-if="form.type === 'maxValueThree'" class="divContainer">
              <div style="width: 100%; margin: 8px">
                <table style="width: 100%">
                  <tr>
                    <th></th>
                    <th v-for="(category, indexCategory) in form.questionCategories" :key="indexCategory"  style="text-align: center">
                      {{category.text}}
                    </th>
                  </tr>
                  <tbody>
                    <tr v-for="(section, sectionIndex) in item.result.sections"
                        :key="sectionIndex"
                    >
                      <td style="max-width: 50px; text-align: center;">
                        {{section.text}}
                      </td>
                      <td v-for="(itemOption, indexOption) in section.categories"
                          :key="indexOption"
                          :style=" (Number(itemOption.value)) ? 'text-align: center;' : 'background-color: #303030;'"
                      >
                        {{ Number(itemOption.value) || '' }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th> Total </th>
                    <td v-for="(itemOption, indexOption) in item.result.totalsByQuestionCategory"
                        :key="indexOption"
                        style="text-align: center"
                    >
                      {{ itemOption.value }}
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <br/>
              <VDivider></VDivider>
              <br/>
              <span style="font-size: 16px;">
                Os pontos foram registrados em forma de gráfico, sendo a pontuação maior correspondente a 5,
                depois 4, 3, 2 e assim até a menor pontuação ser 1.
              </span>
              <br/>
              <br/>
              <div class="chart">
                <canvas id="chartWithMaxValueThree" />
              </div>
            </div>
            <div v-else-if="form.type === 'maxValueFiveAndAnswerIsDivided'">
              <div style="display: flex; flex-direction: column; margin: 10px">
                <div class="my-2">
                  {{`${item.result.first.text}: ${item.result.first.value}`}}
                </div>
                <VDivider></VDivider>
                <div class="my-2">
                  {{`${item.result.second.text}: ${item.result.second.value}`}}
                </div>
                <VDivider></VDivider>
                <div class="my-2" style="display: flex; flex-direction: row; justify-content: space-between">
                  <div>
                    {{`Resultado: ${item.result.first.text} / ${item.result.second.text} = ${item.result.result}`}}
                  </div>
                  <div class="mx-2">
                    <VIcon
                        class="mx-2" color="primary"
                        @click="openTip('', 'controlLocus')"
                    >
                      mdi-lightbulb-on-outline
                    </VIcon>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="form.type === 'inventarioDeComportamentoPessoasDeResultado'" class="divContainer">
              <div style="width: 100%; padding: 8px">
                <table style="width: 100%">
                  <tr>
                    <th></th>
                    <th style="text-align: center; width: 50px;">
                      Pontuação obtida
                    </th>
                    <th style="text-align: center; width: 50px;">
                      Fator de correção
                    </th>
                    <th style="text-align: center; width: 50px;">
                      Total corrigido
                    </th>
                  </tr>
                  <tbody>
                  <tr v-for="(category, categoryIndex) in item.result"
                      v-show="categoryIndex !== 'TRPVmNDxajLquEn3i98c'"
                      :key="categoryIndex"
                  >
                    <td style="max-width: 100px; text-align: center;">
                      {{category.text}}
                    </td>
                    <td style="text-align: center;">
                      {{category.value}}
                    </td>
                    <td style="text-align: center;">
                      {{category.factor}}
                    </td>
                    <td style="text-align: center;">
                      {{category.valueAdjusted}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <br/>
              <VDivider></VDivider>
              <br/>
              <div>
                <div style="margin: 10px">
                  <canvas id="chartInventarioDeComportamentoPessoasDeResultado" />
                </div>
              </div>
            </div>
            <div v-else-if="form.type === 'countOptionsByQuestionCategories' || form.type === 'countOptionsByQuestionCategoriesWithSingleAnswerInSection'" class="divContainer">
              <div style="width: 100%; padding: 8px">
                <table style="width: 100%">
                  <tr>
                    <th></th>
                    <th style="text-align: center; width: 50px;">
                      Total
                    </th>
                    <th v-if="form.type === 'countOptionsByQuestionCategories'" style="text-align: center; width: 50px;">
                      Média
                    </th>
                    <th v-if="form.type === 'countOptionsByQuestionCategories'" style="text-align: center; width: 50px;">
                      Info.
                    </th>
                  </tr>
                  <tbody>
                  <tr v-for="(category, categoryIndex) in item.result.totalsByQuestionCategory"
                      :key="categoryIndex"
                  >
                    <td style="max-width: 100px; text-align: center;">
                      {{category.text}}
                    </td>
                    <td style="text-align: center;">
                      {{category.value}}
                    </td>
                    <td v-if="form.type === 'countOptionsByQuestionCategories'" style="text-align: center;">
                      {{ round((category.value | 0) / (category.qtd || 0)) }}
                    </td>
                    <td v-if="form.type === 'countOptionsByQuestionCategories'" style="text-align: center;">
                      <VIcon
                          class="mx-2" color="primary"
                          @click="openTip(categoryIndex, 'category')"
                      >
                        mdi-lightbulb-on-outline
                      </VIcon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <br/>
              <VDivider></VDivider>
              <br/>
              <div>
                <div style="margin: 10px">
                  <canvas id="chartCountOptionsByQuestionCategories" />
                </div>
              </div>
            </div>
            <div v-else-if="form.type === 'sumAllWithMultiplierInQuestion'" class="divContainer">
              <div style="width: 100%; padding: 8px">
                <table style="width: 100%">
                  <tr>
                    <th></th>
                    <th style="text-align: center; width: 50px;">
                      Aspecto Positivo
                    </th>
                    <th style="text-align: center; width: 50px;">
                      Aspecto Negativo
                    </th>
                    <th style="text-align: center; width: 50px;">
                      Resultado Final
                    </th>
                    <th>Info.</th>
                  </tr>
                  <tbody>
                  <tr v-for="(category, categoryIndex) in item.result.totalsByQuestionCategory"
                      :key="categoryIndex"
                  >
                    <td style="max-width: 100px; text-align: center;">
                      {{category.text}}
                    </td>
                    <td style="text-align: center;">
                      {{category.valuePlus}}
                    </td>
                    <td style="text-align: center;">
                      {{ round(category.valueLess) }}
                    </td>
                    <td style="text-align: center;">
                      {{ round(category.value) }}
                    </td>
                    <td style="text-align: center;">
                      <VIcon
                          class="mx-2" color="primary"
                          @click="openTip(categoryIndex, 'category')"
                      >
                        mdi-lightbulb-on-outline
                      </VIcon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <br/>
              <VDivider></VDivider>
              <br/>
              <div>
                <div style="margin: 10px">
                  <canvas id="chartSumAllWithMultiplierInQuestion" />
                </div>
              </div>
            </div>
            <div v-else-if="form.type === 'indicadorTipologico'">
              <div v-for="(option, indexOption) in item.result" :key="indexOption" class="mx-2 my-1">
                <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 4px 4px;">
                  <div> {{ option.text }}</div>
                  <VIcon
                      class="mx-2" color="primary"
                      @click="openTip(option.text, 'personality')"
                  >
                    mdi-lightbulb-on-outline
                  </VIcon>
                </div>
                <VDivider></VDivider>
                <br/>
              </div>
            </div>
            <div v-else>
              <div v-for="(option, indexOption) in item.result.totalsByQuestionCategory" :key="indexOption" class="mx-2 my-1">
                <div v-if="indexOption !== 'TRPVmNDxajLquEn3i98c'" style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 4px 4px;">
                  <div> {{ option.text }}</div>
                  <div style="display: flex; flex-direction: row; padding: 4px 4px;">
                    <div> {{ `${option.count || option.value ||(defaultString[form.type] ? '' : 0)} ${enablePercent[form.type] ? '%' : ''}` }} </div>
                    <div class="mx-2">
                      <VIcon
                          v-if="form.type === 'maxValueThreeAndResultInPercentCategory'"
                          class="mx-2" color="primary"
                          @click="openTip(indexOption, 'category')"
                      >
                        mdi-lightbulb-on-outline
                      </VIcon>
                    </div>
                  </div>
                </div>
                <VDivider></VDivider>
                <br/>
              </div>
            </div>
          </VCard>
        </div>
        <br/>
        <br/>
        <h2 >Questões </h2>
        <div v-for="(section, sectionIndex) in form.sections" :key="'section_'+section.id" class="section">
          <div class="sectionContent">
            <div class="sectionHeader">
              {{section.name}}
            </div>
            <div v-for="item in form.sections[sectionIndex].items" :key="'item_'+section.id+'_'+item.id" class="questionResponses">
              <div class="questionResponse">
                <div class="body ck-content" v-html="$trdb(item.body)"></div>
                <div v-if="item.type===FormInputType.text">
                  <VTextField
                      v-model="response.sections[section.id].items[item.id].responseText"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                  ></VTextField>
                </div>
                <div v-if="item.type===FormInputType.number">
                  <VTextField
                      v-model="response.sections[section.id].items[item.id].responseNumber"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      type="number"
                  ></VTextField>
                </div>
                <div v-if="item.type===FormInputType.paragraph">
                  <VTextarea
                      v-model="response.sections[section.id].items[item.id].responseText"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      outlined
                  ></VTextarea>
                </div>
                <div v-if="item.type===FormInputType.radio">
                  <div class="questionResponseHeader">
                    {{`${item.name} ${item.mandatory?'*':''}`}}
                  </div>
                  <VRadioGroup
                      v-model="response.sections[section.id].items[item.id].responseOption"
                      :rules="item.mandatory?[rules.required, checkSum(item, section, response.sections[section.id])]:[]"
                      @change="changeOptions(section.id, item.id)"
                  >
                    <VRadio
                        v-for="option in item.options"
                        :key="'option_'+section.id+'_'+item.id+'_'+option.id"
                        :label="option.name"
                        :value="option.id"
                    ></VRadio>
                    <VRow v-if="item.other" align="center">
                      <VRadio
                          :label="$t('formInput.responseOther')"
                          value="other"
                          class="mr-2 ml-3"
                      ></VRadio>
                      <VTextField
                          v-model="response.sections[section.id].items[item.id].responseText"
                          class="mr-3"
                          :placeholder="$t('formInput.responseOtherTextField')"
                          :disabled="response.sections[section.id].items[item.id].responseOption!=='other'"
                      ></VTextField>
                    </VRow>
                  </VRadioGroup>
                </div>
                <div v-if="item.type===FormInputType.checkbox">
                  <div class="questionResponseHeader">
                    {{`${item.name} ${item.mandatory?'*':''}`}}
                  </div>
                  <VCheckbox
                      v-for="option in item.options"
                      :key="'option_'+section.id+'_'+item.id+'_'+option.id"
                      v-model="response.sections[section.id].items[item.id].responseOptions[option.id]"
                      :label="option.name"
                      hide-details
                      :rules="item.mandatory?[checkboxRequired(response.sections[section.id].items[item.id].responseOptions)]:[]"
                  ></VCheckbox>
                  <VRow v-if="item.other" align="center" class="mt-1">
                    <VCheckbox
                        v-model="response.sections[section.id].items[item.id].responseOptions.other"
                        :label="$t('formInput.responseOther')"
                        class="mr-2 ml-3"
                        :rules="item.mandatory?[checkboxRequired(response.sections[section.id].items[item.id].responseOptions)]:[]"
                        @change="changeOptionsCheckbox(section.id, item.id)"
                    ></VCheckbox>
                    <VTextField
                        v-model="response.sections[section.id].items[item.id].responseText"
                        class="mr-3"
                        :placeholder="$t('formInput.responseOtherTextField')"
                        :disabled="!response.sections[section.id].items[item.id].responseOptions.other"
                    ></VTextField>
                  </VRow>
                </div>
                <div v-if="item.type===FormInputType.select">
                  <VSelect
                      v-model="response.sections[section.id].items[item.id].responseOption"
                      :items="item.options"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      item-text="name"
                      item-value="id"
                  ></VSelect>
                </div>
                <div v-if="item.type===FormInputType.date">
                  <FcmDate
                      v-model="response.sections[section.id].items[item.id].responseDate"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      type="iso"
                  ></FcmDate>
                </div>
                <div v-if="item.type===FormInputType.dateTime">
                  <FcmDate
                      v-model="response.sections[section.id].items[item.id].responseDateTime"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      type="iso"
                      pick-time
                      format="DD/MM/YYYY HH:mm"
                      mask="##/##/#### ##:##"
                  ></FcmDate>
                </div>
                <div v-if="item.type===FormInputType.time">
                  <FcmTimer
                      v-model="response.sections[section.id].items[item.id].responseTime"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                  ></FcmTimer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VForm>
      <FcmDialog ref="FcmDialog">
        <div class="body ck-content" v-html="$trdb(tip)"></div>
      </FcmDialog>
      <div class="actions" style="display: flex; flex-direction: row; margin-top: 10px;">
        <VBtn class="mr-2" color="warning" @click="cancel">{{$t('formInput.cancel')}}</VBtn>
        <VSpacer></VSpacer>
        <VBtn class="mr-2" color="success" :disabled="!canEdit || tryingToUpdate || responseSend" @click="submitResponse">{{$t('formInput.submitResponse')}}</VBtn>
      </div>
    </div>
    <div class="version">
      {{version}}
    </div>

  </div>
</template>

<style lang="scss">

.formResponse {
  .v-label {
    color: var(--v-text-base)!important;
  }
  .theme--dark.v-label {
    color: var(--v-text-base)!important;
  }
}

</style>

<style lang="scss" scoped>
// @import '@design';
.body {
  padding: 15px;
}

.box {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .loading {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .version {
    color: #5a5a5a;
    padding: 45px 20px 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    justify-self: flex-end;
    flex: 1;
  }

  .mainMessage {
    padding: 20px;
  }

  .content {
    max-width: 900px;
    width: 80%;
    @media screen and (max-width: 920px) {
      width: 95%;
    }
    .header {
      margin-top: 15px;
    }
    .body {
      //::v-deep img {
      //  max-width: 100%;
      //  max-height: 100%;
      //}
    }
    .sections {
      .section {
        min-height: 80px;
        border-radius: 7px;
        background-color: var(--v-modalBg-base)!important;
        padding: 10px;
        margin-bottom: 10px;
        .sectionContent {
          .sectionHeader {

          }
          .questionResponses {
            .questionResponse {
              margin: 0 0 7px;
              padding: 10px 10px 10px;
              min-height: 30px;
              background-color: var(--v-listItemBg-base)!important;
              border-radius: 7px;

              .questionResponseHeader {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .actions {

    }
  }

  .divContainer {
    max-width: 99%;
    overflow: scroll;
    //display: inline-block;
    margin: 4px 4px;

    .grabDiff {
      cursor: grab;
    }
    table {
      font-size: 15px;
      margin-top: 15px;
      border-collapse: collapse;
      border-spacing: 0;
      border: 0.5px solid var(--v-listItemBorder-base);
      margin-right: 10px;

      .tdNames {
        min-width: 200px;
      }
      th, td {
        border: 2px solid var(--v-listItemBorder-base);
        padding: 1px 5px 5px 4px;
      }
      thead th {
        position: sticky;
        //top: 47px;
        //  left: calc(var(--jwApplicationLeft) - 1px);
        z-index: 2;
        background-color: var(--v-background-base);
        font-size: 15px;
      }
      th {
        min-width: 50px;
      }

      thead tr th:first-child {
        left: calc(var(--jwApplicationLeft) - 1px);
        z-index: 3;
      }

      tbody th {
        background-color: var(--v-background-base);
      }
      .trFooter th {
        background-color: var(--v-background-base);
        text-align: left;
      }

      tbody tr th:first-child {
        position: sticky;
        left: calc(var(--jwApplicationLeft) - 1px);
        // top: 83px;
        z-index: 1;
        text-align: center;
      }
    }
  }
}
</style>





















