export const personalities = {
    INTJ: 'Arquiteto: Pensadores criativos e estratégicos, com um plano para tudo.',
    INTP: 'Lógico: Lógico: Inventores criativos, com uma sede insaciável de conhecimento.',
    ENTJ: 'Comandante: Líderes corajosos, criativos e determinados, sempre dando um jeito para tudo.',
    ENTP: 'Inovador: Pensadores espertos e curiosos, que não resistem a um desafio intelectual.',
    INFU: 'Apoiador: Idealistas serenos e místicos, porém muito inspiradores e incansáveis.',
    INFP: 'Mediador: Pessoas poéticas, bondosas e altruístas, sempre prontas para apoiar uma boa causa.',
    ENFJ: 'Protagonista: Líderes carismáticos e inspiradores, capazes de hipnotizar seus ouvintes.',
    ENFP: 'Ativista: Espíritos livres animados, criativos e sociáveis, que sempre encontram um motivo para sorrir.',
    ISTJ: 'Prático: Indivíduos pragmáticos e focados em fatos, com uma confiabilidade indiscutível.',
    ISFJ: 'Defensor: Protetores muito dedicados e acolhedores, sempre prontos para defender quem amam.',
    ESTJ: 'Executivo: Administradores excelentes, insuperáveis ao gerenciar tanto coisas quanto pessoas.',
    ESFJ: 'Cônsul: Pessoas extraordinariamente atenciosas, sociáveis e populares, sempre prontas para ajudar.',
    ISTP: 'Virtuoso: Experimentadores ousados e práticos, mestres em todos os tipos de ferramentas.',
    ISFP: 'Aventureiro: Artistas flexíveis e encantadores, sempre prontos para explorar e experimentar algo novo.',
    ESTP: 'Empreendedor: Pessoas habilidosas, ativas e muito perceptivas, que realmente gostam de se arriscar.',
    ESFP: 'Animador: Pessoas espontâneas, ativas e animadas, a vida nunca fica entediante perto delas.',
};

export const controlLocus = '<h3><strong>Interpretação dos Resultados</strong></h3><br/><p><strong>Abaixo de 1,0</strong> - Indica que você possui um alto nível de orientação por controle externo, com poucas possibilidades de iniciar uma atividade como empreendedor ou de se destacar profissionalmente.</p><p><strong>Entre 1,0 e 2,9</strong> - Indica que você até pode vir a ser um empreendedor, mas é importante aumentar o seu nível de controle interno nas suas atividades profissionais.</p><p><strong>Entre 3,0 e 4,9 </strong>- Indica que você tem um bom nível de controle interno.</p><p><strong>Entre 5,0 e 6,9</strong> - Indica que você tem um excelente nível de controle interno, com grande possibilidade de iniciar uma atividade como empreendedor ou de se destacar profissionalmente.</p><p><strong>De 7,0 em diante </strong>- Indica que você tem um nível de controle interno fora do comum. Dificilmente suportara estruturas corporativas muito rígidas.</p>';