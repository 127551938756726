<script>
export default {
  name: 'FcmDialog',
  components: {},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '850px'
    },
    hideButtonClose: {
      type: Boolean,
    },
    fullscreen: {
      type: Boolean
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: ''
    }
  },
  data(vm) {
    return {
      internalLazyValue: vm.value,
      items: [],
    };
  },
  computed: {
    showModal: {
      get() {
        return this.internalLazyValue;
      },
      set(val) {
        if(this.internalLazyValue===val) {
          return;
        }
        this.internalLazyValue = val;
        this.$emit('input', val);
      }
    }
  },
  watch: {
    value(val) {
      this.internalLazyValue = val;
    },
  },
  mounted: async function() {
  },
  beforeDestroy: function() {
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit('close');
    },
    openModal() {
      this.showModal = true;
    },
  },
};
</script>


<template>
  <div :class="$style.box">
    <VDialog
      v-model="showModal"
      :scrollable="scrollable"
      :max-width="maxWidth"
      :content-class="$style.modal +' '+ contentClass"
      :persistent="persistent"
    >
      <VCard tile>
        <VToolbar flat>
          <VBtn v-if="!hideButtonClose"
                icon
                @click="closeModal"
          >
            <VIcon>mdi-close</VIcon>
          </VBtn>
          <VToolbarTitle v-if="title">
            {{ title }}
          </VToolbarTitle>
          <VSpacer />
        </VToolbar>
        <VCardText :class="$style.body">
          <slot />
        </VCardText>

        <div style="flex: 1 1 auto;" />
      </VCard>
    </VDialog>
  </div>
</template>


<style lang="scss" module>
  //@import '@design';
  .modal {
    background-color: var(--v-modalBg-base);
    .buttonSave {
      color: var(--v-buttonBg-base)!important;
    }

    .body {
      padding: 13px 5px 6px!important;
      flex: 80 1 auto!important;
    }
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 24%), 0px 24px 38px 3px rgb(10 10 10 / 14%), 0px -45px 40px -6px rgb(58 58 58 / 12%);
  }

  .box {
  }
</style>





















