'use strict';

const toExport = {};

if (!process.env.GCLOUD_PROJECT_ON_BUILD && process.env.GCLOUD_PROJECT) {
  process.env.GCLOUD_PROJECT_ON_BUILD = process.env.GCLOUD_PROJECT;
}

const links = {
  site: {
    name: 'Site',
    link: 'https://orazen-16484.web.app/'
  },
  /* facebook: {
     name: 'Facebook',
     link: 'https://www.facebook.com/Sauver-112736837169018'
   },
   instagram: {
     name: 'Instagram',
     link: 'https://www.instagram.com/sauver_med'
   } */
};

const android = {
  linkStore: 'https://play.google.com/store/apps/developer?id=JustWorks',
  storeCode: '',
  id: 'br.com.justworks.jw-vue-boilerplate',
  uri: 'vnd.jw_vue_boilerplate://',
  name: 'JW vue Boilerplate',
  packageName: 'br.com.justworks.jw-vue-boilerplate'
};

const ios = {
  linkStore: 'https://apps.apple.com/br/developer/alexandre-reis-corr%C3%AAa-cruz/id429125678',
  storeCode: 'id15168907wer',
  id: 'id15168907we',
  uri: 'vnd.jw_vue_boilerplate://',
  name: 'JW vue Boilerplate',
  packageName: '',
};

toExport.listConfig = {
  'default': {
    configId: 'defaultOrazen',
    name: 'Default ALGO DE ERRADO NÃO ESTA CERTO',
    maps: {
      key: 'AIzaSyAjSPrpte94NNTEnfqToUNhczN0RsVe1M4'
    },
    title: 'Orazen',
    description: 'Orazen!',

    baseUrl: 'https://orazen.com.br/',
    baseUrlForms: 'https://forms.orazen.com.br/',
    realtimeDatabaseAdditionals: [],
    firebase: {
      apiKey: 'AIzaSyAIH1tkP9RDG5HV0g2uLjwmezF0_gCzZWQ',
      authDomain: 'orazen-16484.firebaseapp.com',
      projectId: 'orazen-16484',
      databaseURL: 'https://orazen-16484-default-rtdb.firebaseio.com/',
      storageBucket: 'orazen-16484.appspot.com',
      messagingSenderId: '139058268623',
      appId: '1:139058268623:web:7e7477fc0a9128372eaaba',
      measurementId: 'G-1ZQN2PTPTV'
    },
    functionsUrl: 'https://southamerica-east1-orazen-16484.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#3acdc8',
    msTileColor: '#3acdc8',
    appleMobileWebAppCapable: 'yes',
    appleMobileWebAppStatusBarStyle: 'black',
    emailKey: 'admin.orazen.com.br',
    region: 'southamerica-east1',
    isDevServer: false,
  },
  'orazen-16484': {
    configId: 'orazen-16484',
    name: 'Orazen PRODUCTION',
    maps: {
      key: 'AIzaSyAjSPrpte94NNTEnfqToUNhczN0RsVe1M4'
    },
    title: 'Orazen',
    description: 'Orazen!',

    baseUrl: 'https://forms.orazen.com.br/',
    baseUrlForms: 'https://forms.orazen.com.br/',
    realtimeDatabaseAdditionals: [],
    firebase: {
      apiKey: 'AIzaSyAIH1tkP9RDG5HV0g2uLjwmezF0_gCzZWQ',
      authDomain: 'orazen-16484.firebaseapp.com',
      projectId: 'orazen-16484',
      databaseURL: 'https://orazen-16484-default-rtdb.firebaseio.com/',
      storageBucket: 'orazen-16484.appspot.com',
      messagingSenderId: '139058268623',
      appId: '1:139058268623:web:7e7477fc0a9128372eaaba',
      measurementId: 'G-1ZQN2PTPTV'
    },
    functionsUrl: 'https://southamerica-east1-orazen-16484.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#3acdc8',
    msTileColor: '#3acdc8',
    appleMobileWebAppCapable: 'yes',
    appleMobileWebAppStatusBarStyle: 'black',
    emailKey: 'admin.orazen.com.br',
    region: 'southamerica-east1',
    isDevServer: false,
  },
};

toExport.config = toExport.listConfig['orazen-16484'];
console.log('Server config start configName: ' + toExport.config.name + ' on project: ' + (process.env.GCLOUD_PROJECT_ON_BUILD || ''));

// console.log('Server config start configName: ' + toExport.config.name);
if (typeof window !== 'undefined' && window?.location?.origin?.includes('localhost')) {
  toExport.config.baseUrl = window.location.origin;
}


toExport.appConfig = toExport.config;

module.exports = toExport;
