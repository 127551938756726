import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import "./plugins/axios";
import pluginGlobal from '@src/plugins/global.js';
import "roboto-fontface/css/roboto/roboto-fontface.css";
import '@fortawesome/fontawesome-free/css/all.css';
import VueTheMask from 'vue-the-mask';
import "@mdi/font/css/materialdesignicons.css";
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

import '@src/design/sweetalert2Theme.scss';
import moment from 'moment';
require('moment/locale/pt-br');
window.moment = moment;

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(VueSweetalert2);
Vue.use(pluginGlobal);
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
