module.exports = {
  all: {
    goBack: 'Voltar',
  },
  FormInputType: {
    text: 'Texto',
    paragraph: 'Parágrafo',
    number: 'Número',
    radio: 'Escolha individual',
    checkbox: 'Escolha multipla',
    select: 'Seleção',
    date: 'Data',
    time: 'Hora',
    dateTime: 'Data e hora',
  },
  formInput: {
    type: "Tipo",
    other: "Mostrar \"Outra...\"",
    otherText: "Outra...",
    addOption: "Add Opção",
    addSection: "Add Seção",
    confirmRemoveSection: "Tem certeza que deseja remover essa seção e todos os itens dessa seção?",
    confirmRemoveInput: "Tem certeza que deseja remover essa questão",
    mandatory: "Obrigatório",
    sessionName: "Nome da seção",
    inputName: "Nome da opção",
    inputOption: "Opção",
    formNotFound: "Formulário não encontrado",
    submitResponse: "Enviar resposta",
    responseSend: "Resposta enviada",
    responseOther: "Outra",
    responseOtherTextField: "Descreva",
    cancel: "Cancelar",
    link: "Vínculo",
    canEditResponse: "Pode editar resposta",
    errorValidate: "Há campos obrigatórios não preenchidos",
    errorOnWriteCall: "Erro ao tentar gravar no banco",
    errorOnSave: "Erro ao tentar salvar dados",
    errorConnection: "Erro ao conectar com o servidor, verifique a conexão com a internet",
    errorTimeOver: "Tempo expirado para responder o formulário",
    tryLoadFormAgain: "Tentar carregar o formulário novamente",
    responseSendPrevious: "Formulário já respondido",
    errorPermissionDenied: "Permissão negada",
  },
  FormLinkType: {
    none: "Nenhum",
    eventId: "Id do evento",
    eventAnesthetistName: "Nome do anestesista",
    eventPatientName: "Nome do paciente",
    eventDate: "Data da anestesia",
    userId: "Id do usuário",
    userName: "Nome do usuário",
    userEmail: "Email do usuário",
    companyId: "Id da empresa",
    companyName: "Nome da empresa",
  },
  formInputEjs: {
    title: "",
    notFound: "Post Não Encontrado"
  },
  form: {
    updateSuccess: "Dados salvos",
    promptQuestion: "",
    confirmQuestion: "Você tem certeza?",
    confirmQuestionYes: "Sim",
    confirmQuestionCancel: "Cancelar",
    errorUpdate: "Houve algum erro",
    error: {
      invalidCPF: "CPF  inválido",
      justNumbers: "Digite apenas números",
      required: "Esse campo é requerido",
      minLength: "O campo é muito curto",
      maxLength: "O campo é muito longo",
      update: "Houve algum erro",
      email: "Email inválido",
    }
  }
};



