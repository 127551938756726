const axios = require('axios');
const urljoin = require('url-join');
const Emitter = require('tiny-emitter');
const emitter = new Emitter();

/**
 *
 * @type {{uid: string, apiKey: string, expirationTime: number, accessToken: string, refreshToken: string}}
 */
let defaultAuth = {
  apiKey: '',
  refreshToken: '',
  accessToken: '',
  expirationTime: 0,
  uid: '',
};

/**
 *
 * @type {{uid: string, apiKey: string, expirationTime: number, accessToken: string, refreshToken: string}}
 */
let auth = {
  ...defaultAuth,
};



let headers = {
  'Content-Type': 'application/json; charset=utf-8',
  'Authorization': '',
};

let config = {
  functionsUrl: '',
  baseUrl: '',
};

function delay(ms){
 return new Promise((resolve) => setTimeout(resolve, ms));
}


window.newUserToken = (token) => {
  emitter.emit('newUserToken', token);
};

async function jwRequestNewToken() {
  if(typeof androidInterface==='object' && typeof androidInterface?.requestToken==='function') {
    return new Promise((resolve)=>{
      androidInterface.requestToken();
      let finish = false;
      let timeId = setTimeout(()=>{
        if(!finish) {
          finish = true;
          resolve(false);
        }
      }, 10000);
      emitter.on('newUserToken', function (token) {
        clearTimeout(timeId);
        if(!finish) {
          finish = true;
          resolve(token);
        }
      });
    });
  }
  return false;
}

async function getAccessToken() {
  if(!auth.apiKey) {
    console.warn('need refreshToken or apiKey');
    return false;
  }
  if(!auth.refreshToken) {
    let newToken = await jwRequestNewToken();
    if(newToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + newToken,
      };
      auth.accessToken = newToken;
      return true;
    }
    return false;
  }

  headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': '',
  };
  let params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', auth.refreshToken);

  let request = null;
  try {
    request = await axios({
      method: 'post',
      url: 'https://securetoken.googleapis.com/v1/token?key='+auth.apiKey,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: params,
    });
  } catch(err) {
    console.error('Erro ao tentar buscar token', err);
  }

  if(request?.data?.access_token) {
    auth.uid = request.data.user_id || auth.uid || '';
    auth.accessToken = request.data.access_token || auth.accessToken || '';
    auth.refreshToken = request.data.refresh_token || auth.refreshToken || '';
    auth.expirationTime = 0;
    if(request.data.expires_in) {
      auth.expirationTime = new Date().getTime() + parseInt(request.data.expires_in)*1000;
    }
    if(auth.accessToken) {
      headers['Authorization'] = 'Bearer ' + auth.accessToken;
    }
    return true;
  }
  return false;
}

async function makeOnCall (name, data) {
  return axios({
    method: 'post',
    url: urljoin('https://forms.orazen.com.br/', name),
    // url: urljoin(config.baseUrl, name),
    headers: headers,
    data: {
      data: data,
    }
  });
}

export async function callOnCall(name, data, tries=1) {
  let gotToken = false;
  if(auth.expirationTime<new Date().getTime()) {
    await getAccessToken();
    gotToken = true;
  }
  let tryAgain = false;
  let response = null;
  let responseError = null;
  let error = null;
  try {
    response = await makeOnCall(name, data);
  } catch(err) {
    error = err;
    window.fcmError = err;
    try {
      responseError = JSON.parse(err?.request?.response);
    } catch(e) {}
    if(!gotToken && err?.request?.status===401 && responseError?.error?.status==='UNAUTHENTICATED') {
      tryAgain = true;
      error = null;
      response = null;
    }
    else {
      console.error('Erro na callOnCall 1', err);
    }
  }

  if(tryAgain) {
    await getAccessToken();
    try {
      response = await makeOnCall(name, data);
    } catch(err) {
      error = err;
      window.fcmError = err;
      console.error('Erro na callOnCall 2', err);
    }
  }
  window.fcmResponse = response;

  if(error?.isAxiosError && !error?.response) {
    console.log('NetworkError');

    if(tries>=4) {
      return {
        data: null,
        status: 490,
      };
    }
    else {
      await delay(tries*tries*1000);
      return callOnCall(name, data, tries+1);
    }

  }

  return {
    data: response?.data?.result || null,
    status: response?.status || 0,
  };
}


export function setConfig(args) {
  config.functionsUrl = args.functionsUrl;
  config.baseUrl = args.baseUrl;
}

export function setAuth(args) {
  auth = {
    ...defaultAuth,
    ...args,
  };
  headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': '',
  };
  if(auth.accessToken) {
    headers['Authorization'] = 'Bearer ' + auth.accessToken;
  }
}





