<script>
// import StringUtils from '@common/StringUtils'
// import {EventStatus, ProcedureType, Accommodation} from "@common/status";
// https://stackoverflow.com/questions/57022263/vuetify-v-menu-closes-immediately-after-opening-form-on-click-event-of-a-third-p?rq=1
import isEqual from "lodash/isEqual";
const DEFAULT_VALUE = null;

export default {
  name: 'FcmTimer',
  components: {},
  model: {
    prop: 'jwValue',
    event: 'jwInput'
  },
  props: {
    jwValue: {
      validator: (value)=>{return true},
      default: ()=>{return DEFAULT_VALUE;},
    },
    fixOnStart: {
      type: Boolean,
    },
    label: {
      validator: (value)=>{return true},
      default: '',
    },
    inputmode: {
      type: String,
      default: 'none',  // numeric || none
    },
  },
  data(vm) {
    return {
      internalLazyValue: vm.updateInternalLazyValue(vm.jwValue, vm.fixOnStart),
      internalErrorMessages: [],
      hasInput: false,
      menu: false,
      closeOnClick: false,
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.internalLazyValue;
      },
      set(val) {
        this.updateInternalLazyValue(val, true);
      }
    },
  },
  watch: {
    value(val) {
      this.updateInternalLazyValue(val, this.fixOnStart);
    },
  },
  mounted: async function() {

  },
  beforeDestroy: function() {
  },
  methods: {
    reset () {
      this.hasInput = false;
      this.internalErrorMessages = [];
      this.updateInternalLazyValue(DEFAULT_VALUE, true);
      this.removeFocus();
    },
    resetValidation () {
      this.hasInput = false;
      this.internalErrorMessages = [];
    },
    validate (force=false) {
      this.internalErrorMessages = [];
      for (const rule of this.rules) {
        const result = typeof rule === 'function' ? rule(this.internalLazyValue) : rule;
        if (typeof result === 'string') {
          this.internalErrorMessages.push(result);
        }
      }
      if (force) {
        this.hasInput = true;
      }
      return this.internalErrorMessages.length === 0;
    },



    /**
     * Formato dos dados de saída
     */
    toModel(value) {
      return value;
    },
    removeFocus() {

    },
    /**
     * Alterar esse método para manter a consistência do seu dado interno (internalLazyValue)
     * @param value
     * @param emit
     */
    updateInternalLazyValue(value, emit=false) {
      if(!value) {
        value = DEFAULT_VALUE;
      }
      if(value===DEFAULT_VALUE && this.internalLazyValue===DEFAULT_VALUE) {
        return this.internalLazyValue;
      }
      if (value===DEFAULT_VALUE || this.internalLazyValue===DEFAULT_VALUE || !isEqual(this.internalLazyValue, value)) {
        this.resetValidation();
        this.internalLazyValue = value;
        if(emit) {
          this.$emit('jwInput', this.toModel(this.internalLazyValue));
        }
      }
      return this.internalLazyValue;
    },
    emitClick(on, event) {
      this.closeOnClick = false;
      setTimeout(()=>{
        on.click(event);
      }, 100);
      setTimeout(()=>{
        this.closeOnClick = true;
      }, 500);
    },
  },
}
</script>


<template>
  <VMenu
      ref="menu"
      v-model="menu"
      :close-on-click="closeOnClick"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="internalValue"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <VTextField
          v-model="internalValue"
          :label="label"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          :inputmode="inputmode"
          v-bind="{...attrs, ...$attrs}"

          @click:prepend-inner="emitClick(on, $event)"
          @click="emitClick(on, $event)"
          @keydown="on.keydown($event)"
      ></VTextField>
    </template>
    <VTimePicker
        v-if="menu"
        v-model="internalValue"
        full-width
        format="24hr"
        @click:minute="$refs.menu.save(internalValue)"
    ></VTimePicker>
  </VMenu>
</template>




















