<script>
// import {Timestamp} from '@src/db';
// https://stackoverflow.com/questions/57022263/vuetify-v-menu-closes-immediately-after-opening-form-on-click-event-of-a-third-p?rq=1

export default {
  name: 'FcmDate',
  components: {},
  inject: {
    form: { default: null }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      validator: (value)=>{return true},
      default: ()=>{return null;},
    },
    min: {
      validator: (value)=>{return true},
      default: ()=>{return undefined;},
    },
    max: {
      validator: (value)=>{return true},
      default: ()=>{return undefined;},
    },
    label: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    mask: {
      type: [String, Array],
      default: '##/##/####' // ##/##/#### ##:##
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY' // DD/MM/YYYY HH:mm
    },
    type: {
      type: String,
      default: 'firestore',    // firestore || date || string || moment || iso
    },
    modelFormat: {  // Só é relevante no type === string
      type: [String, null],
      default: null
    },
    pickTime: {
      type: Boolean,
    },
    btnDone: {
      type: String,
      default: '',
    },
    startOnYear: {
      type: Boolean,
    },
    startOnMonth: {
      type: Boolean,
    },
    rules: {
      type: Array,
      default: ()=>[],
    },
    readOnly: {
      type: Boolean,
    },
    pickMonth: {
      type: Boolean,
    },
    hideSelected: {
      type: Boolean
    },
    appendIcon: {
      type: String,
      default: undefined,
    },
    inputmode: {
      type: String,
      default: 'none',  // numeric || none
    },
    setNowOnClickIfEmpty: {
      type: Boolean
    },
  },
  data(vm) {
    return {
      showModal: false,
      items: [],
      internalLazyValue: (()=>{
        return vm.toMoment(this.value);
      })(),
      menu: false,
      internalErrorMessages: [],
      hasInput: false,
      activePicker: 'DATE',
      closeOnClick: false,
    }
  },
  computed: {
    shouldValidate() {
      if(this.rules && this.rules.length>0) {
        return true;
      }
      return false;
    },
    hasError () {
      return this.internalErrorMessages.length>0;
    },
    internalValuePicker: {
      get() {
        return this.internalLazyValue?this.internalLazyValue.format('YYYY-MM-DD'):null;
      },
      set(val) {
        if(this.pickTime) {
          val = moment(val, 'YYYY-MM-DD');
          val.hour(this.internalLazyValue?this.internalLazyValue.hour():0);
          val.minute(this.internalLazyValue?this.internalLazyValue.minute():0);
        }
        else {
          val = moment(val, 'YYYY-MM-DD');
        }
        this.updateInternalLazyValue(val);
      }
    },
    internalValuePickerTime: {
      get() {
        return this.internalLazyValue?this.internalLazyValue.format('HH:mm'):'';
      },
      set(val) {
        let tmp = moment(this.internalLazyValue);
        let hourMinute = moment(val, 'HH:mm');
        tmp.hour(hourMinute.hour()||0);
        tmp.minute(hourMinute.minute()||0);
        this.updateInternalLazyValue(tmp);
      }
    },
    internalValueShow: {
      get() {
        return this.internalLazyValue?this.internalLazyValue.format(this.format):'';
      },
      set(val) {

      }
    }
  },
  watch: {
    value(val) {
      val = this.toMoment(val);
      this.updateInternalLazyValue(val, true)
    },
    menu(val) {
      if(val && !this.internalLazyValue && this.setNowOnClickIfEmpty) {
        this.updateInternalLazyValue(moment());
      }
      if(val && this.$refs.timePicker) {
        this.$refs.timePicker.selectingHour = true
      }
      if(val && this.startOnYear) {
        setTimeout(()=>{
          this.activePicker = 'YEAR';
          // if(this.$refs.datePicker) {
          //   this.$refs.datePicker.internalActivePicker = 'YEAR';
          // }
        },50);
      }
      else if(val && (this.startOnMonth || this.pickMonth)) {
        setTimeout(()=>{
          this.activePicker = 'MONTH';
          // if(this.$refs.datePicker) {
          //   this.$refs.datePicker.internalActivePicker = 'MONTH';
          // }
        },50);
      }
      else {
        setTimeout(()=>{
          this.activePicker = 'DATE';
          // if(this.$refs.datePicker) {
          //   this.$refs.datePicker.internalActivePicker = 'DATE';
          // }
        },50);
      }
    }
  },
  created () {
    this.form && this.form.register(this)
  },
  mounted: async function() {
  },
  beforeDestroy: function() {
    this.form && this.form.unregister(this);
  },
  methods: {
    updateInternalLazyValue(value, noEmit=false) {
      if(!value || typeof value.isValid!=='function' || !value.isValid()) {
        value = null;
      }
      if(value===null && this.internalLazyValue===null) {
        return;
      }
      if (value===null || this.internalLazyValue===null || !this.internalLazyValue.isSame(value)) {
        this.resetValidation();
        this.internalLazyValue = value;
        if(!noEmit) {
          this.$emit('input', this.toModel(this.internalLazyValue));
        }
      }
    },
    toMoment(input) {
      if(!input) {
        return null;
      }
      let d = null;
      if(typeof input.toDate ==='function') {
        d = moment(input.toDate());
      }
      else if(typeof input==='string') {
        d = moment(input, this.modelFormat);
      }
      else if(input.seconds>0 || input.nanoseconds>0) {
        d = moment((input.seconds*1000)+(input.nanoseconds/10e6));
      }
      else {
        d = moment(input);
      }
      if(d?.isValid?.()) {
        return d;
      }
      return null;
    },
    toModel(val) {
      if(!(val?.isValid?.())) {
        return null;
      }
      switch (this.type) {
        // case 'firestore':
        //   return new Timestamp.fromDate(val.toDate());
        case 'string':
          return val.format(this.modelFormat || 'YYYY-MM-DD');
        case 'iso':
          if(this.pickTime) {
            return val.format('YYYY-MM-DD HH:mm');
          }
          else {
            return val.format('YYYY-MM-DD');
          }
        case 'date':
          return val.toDate();
        case 'moment':
          return moment(val);
        default:
          console.error('FcmDate: type unknown', this.type);
      }
    },
    saveAction() {
      this.$emit('dialogConclude', null);
      this.showModal = false;
    },
    onBlur(event) {
      if(event.target && event.target.value) {
        const val = moment(event.target.value, this.format);
        this.updateInternalLazyValue(val);
      }
      else {
        this.updateInternalLazyValue(null);
      }
    },
    onPickDate() {
      if(this.pickTime) {

      }
      else {
        this.menu = false;
      }
    },
    onPickTime() {
      this.menu = false;
    },
    done() {
      this.menu = false;
    },

    removeFocus() {
      if(this.$refs.textField && typeof this.$refs.textField.blur==='function') {
        setTimeout(()=>{this.$refs.textField.blur();}, 10);
      }
    },
    reset () {
      this.hasInput = false;
      this.internalErrorMessages = [];
      this.updateInternalLazyValue(null);
      this.removeFocus();
    },
    resetValidation () {
      this.hasInput = false;
      this.internalErrorMessages = [];
    },
    validate (force=false) {
      this.internalErrorMessages = [];
      for (const rule of this.rules) {
        const result = typeof rule === 'function' ? rule(this.internalLazyValue) : rule;
        if (typeof result === 'string') {
          this.internalErrorMessages.push(result);
        }
      }
      if (force) {
        this.hasInput = true;
      }
      return this.internalErrorMessages.length === 0;
    },
    keyEnter(event) {
      const val = moment(event.target.value, this.format);
      if(val.isValid()) {
        this.updateInternalLazyValue(val);
        this.menu = false;
      }
      else {
        this.updateInternalLazyValue(null);
      }
    },
    clickMonth(value) {
      if (this.pickMonth) {
        this.updateInternalLazyValue(moment(value + '-01 12:00'));
        this.menu = false;
      }
    },
    emitClick(on, event) {
      if(this.readOnly) {
        return;
      }
      this.closeOnClick = false;
      setTimeout(()=>{
        on.click(event);
      }, 100);
      setTimeout(()=>{
        this.closeOnClick = true;
      }, 500);
    },
  }
}
</script>


<template>
    <VMenu
        v-model="menu"
        :close-on-click="closeOnClick"
        :close-on-content-click="false"
        :nudge-right="40"
        offset-y
        min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <VTextField
            v-if="!hideSelected"
            ref="textField"
            v-model="internalValueShow"
            v-mask="mask"
            :label="label"
            v-bind="{...attrs, ...$attrs}"
            :error-messages="internalErrorMessages"
            :readonly="readOnly"
            :class="className"
            :inputmode="inputmode"
            :append-icon="appendIcon"
            autocomplete="no"
            prepend-inner-icon="mdi-calendar"
            @blur="onBlur"
            @keyup.enter="keyEnter"

            @click:prepend-inner="emitClick(on, $event)"
            @click="emitClick(on, $event)"
            @keydown="(!readOnly)?on.keydown($event):''"
        >
        </VTextField>
      </template>
      <div class="box">
        <VDatePicker
            ref="datePicker"
            v-model="internalValuePicker"
            color="primary"
            no-title
            :active-picker="activePicker"
            :min="min"
            :max="max"
            @input="onPickDate"
            @click:month="clickMonth"
        ></VDatePicker>
        <VTimePicker
            v-if="pickTime"
            ref="timePicker"
            v-model="internalValuePickerTime"
            color="primary"
            format="24hr"
            no-title
            @click:minute="onPickTime"
        ></VTimePicker>
        <VBtn v-if="btnDone" @click="done">{{btnDone}}</VBtn>
      </div>
    </VMenu>
</template>


<style lang="scss" scoped>
 // @import '@design';
  .box {
    display: flex;
    flex-direction: column;
    background-color: var(--v-modalHeaderBg-base);
  }
</style>





















