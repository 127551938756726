'use strict';
exports.default = exports;

exports.round = function(num, precision = 2) {
    if(!num) {
        return 0;
    }
    if(!precision) {
        precision = 2;
    }
    return +(Math.round(num + 'e+'+precision)  + 'e-'+precision);
};